import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×4\\@85% 1RM`}</p>
    <p>{`Single Leg Hamstring Curls 4×8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`5-Power Cleans (155/105)`}</p>
    <p>{`5-Hang Power Cleans`}</p>
    <p>{`5-Front Squats`}</p>
    <p>{`15-Lateral Burpees Over Bar`}</p>
    <p>{`50-40-30-20-10ft Suicide Sprint`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville’s Barbell Club will be starting this Saturday,
January 19th!  If you’d like to improve your Clean & Jerk and Snatch
these classes are for you.  Classes will be offered Saturday’s (11:15)
and Sunday’s (1:45) each weekend after our CrossFit classes. You can pay
per class (\\$15 for members, \\$20 for non members) or for a discounted
rate for the entire online program plus all classes (\\$120 for members).
Email Daniel for more information.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      